<template>
  <b-row>
    <b-col cols="12">
      <b-form>
        <b-form-group v-if="!recuperarClave" label="Contraseña actual" class="text-left">
          <b-form-input
            type="password"
            v-model="$v.formRC.actual.$model"
            :state="$v.formRC.actual.$dirty ? !$v.formRC.actual.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formRC.actual) }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Nueva contraseña" class="text-left">
          <b-form-input
            type="password"
            v-model="$v.formRC.nueva.$model"
            :state="$v.formRC.nueva.$dirty ? !($v.formRC.nueva.$error || progress < numPasosProgress) : null"
          />
          <b-form-invalid-feedback>
            {{ $v.formRC.nueva.$error ? "Este campo es obligatorio" : progressV }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Confirmar contraseña" class="text-left">
          <b-form-input
            type="password"
            v-model="$v.formRC.confirmada.$model"
            :state="$v.formRC.confirmada.$dirty ? !($v.formRC.nueva.$error || progress < numPasosProgress) : null"
          />
          <b-form-invalid-feedback>
            {{ $v.formRC.confirmada.$error ? "Este campo es obligatorio" : progressV }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-col>
    <b-col cols="12" class="py-2">
      <b-progress :max="numPasosProgress" class="w-100">
        <b-progress-bar :variant="getVariant" :value="validarClave" />
      </b-progress>
    </b-col>
    <b-col cols="12" class="pb-2 content-center">
      <b-button
        variant="danger"
        class="mt-3 mb-2"
        @click="cambiarClave"
        :disabled="$v.formRC.$invalid || progress < numPasosProgress"
      >
        <i class="las la-check-circle" />
        Cambiar
      </b-button>
    </b-col>
    <b-col cols="12">
      <div :class="`text-center ${recuperarClave ? 'fs-12' : 'fs-16'}`">
        <p>Ten en cuenta que tu contraseña debe cumplir con lo siguiente:</p>
        <p class="mb-0">
          <i :class="`las ${feedBack.minMax ? 'la-check-circle success' : 'la-times-circle danger'}`" />
          Debe tener mínimo {{ minLength }} {{ textoMinLength }} y máximo {{ maxLength }}.
        </p>
        <p class="mb-0">
          <i :class="`las ${feedBack.alphanumeric ? 'la-check-circle success' : 'la-times-circle danger'}`" />
          Debe ser alfanumérica.
        </p>
        <p class="mb-0">
          <i :class="`las ${feedBack.minMinusculas ? 'la-check-circle success' : 'la-times-circle danger'}`" />
          Debe tener mínimo {{ minimoLetrasMinusculas }} {{ textoMinimoLetrasMinusculas }}
        </p>
        <p class="mb-0">
          <i :class="`las ${feedBack.minMayusculas ? 'la-check-circle success' : 'la-times-circle danger'}`" />
          Debe tener mínimo {{ minimoLetrasMayusculas }} {{ textoMinimoLetrasMayusculas }}
        </p>
        <p class="mb-0">
          <i :class="`las ${feedBack.maxSpecialCharacters ? 'la-check-circle success' : 'la-times-circle danger'}`" />
          Debe tener mínimo {{ specialCharacters }} {{ textoSpecialCharacters }}
        </p>
        <p class="mb-0">
          <i :class="`las ${feedBack.differentId ? 'la-check-circle success' : 'la-times-circle danger'}`" />
          No puede ser igual a tu número de identificación.
        </p>
        <p class="mb-0" v-if="isOnLogin">
          <i :class="`las ${feedBack.differentLast ? 'la-check-circle success' : 'la-times-circle danger'}`" />
          No puede ser igual que la contraseña anterior.
        </p>
        <p class="mb-0">
          <i :class="`las ${feedBack.passwordEqual ? 'la-check-circle success' : 'la-times-circle danger'}`" />
          Deben coincidir los campos de la nueva contraseña.
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import PublicService from "@/app/core/api/public";
import { required } from "vuelidate/lib/validators";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";

export default {
  mixins: [mixinsEvents],
  props: {
    isOnLogin: { type: Boolean, default: true },
    recuperarClave: { type: Boolean, default: false },
  },
  data() {
    return {
      progress: 0,
      feedBack: {
        minMax: false,
        differentId: false,
        alphanumeric: false,
        differentLast: false,
        passwordEqual: false,
        minMinusculas: false,
        minMayusculas: false,
        maxSpecialCharacters: false,
      },
      formRC: { nueva: null, actual: null, confirmada: null },
    };
  },
  computed: {
    numPasosProgress() {
      return 8;
    },
    getVariant() {
      if (this.progress >= 3 && this.progress < this.numPasosProgress) return "warning";
      if (this.progress === this.numPasosProgress) return "success";
      return "danger";
    },
    textoSpecialCharacters() {
      return this.specialCharacters === "1" ? "carácter especial." : "caracteres especiales.";
    },
    textoMinimoLetrasMinusculas() {
      return this.minimoLetrasMinusculas === 1 ? "letra minúscula." : "letras minúsculas.";
    },
    textoMinimoLetrasMayusculas() {
      return this.minimoLetrasMayusculas === 1 ? "letra mayúscula." : "letras mayúsculas.";
    },
    specialCharacters() {
      return this.$store.getters.specialCharacters;
    },
    minimoLetrasMinusculas() {
      return 1;
    },
    minimoLetrasMayusculas() {
      return 1;
    },
    textoMinLength() {
      return this.minLength === "1" ? "carácter" : "caracteres";
    },
    minLength() {
      return this.$store.getters.minLengthPass;
    },
    maxLength() {
      return this.$store.getters.maxLengthPass;
    },
    validarClave() {
      return this.calcularProgreso();
    },
    progressV() {
      return this.progress < this.numPasosProgress ? "La contraseña debe cumplir con las condiciones." : "";
    },
  },
  validations() {
    if (this.recuperarClave) {
      return {
        formRC: {
          nueva: { required },
          confirmada: { required },
        },
      };
    } else {
      return { formRC: { nueva: { required }, confirmada: { required }, actual: { required } } };
    }
  },
  beforeDestroy() {
    this.cleanForm();
  },
  methods: {
    calcularProgreso() {
      this.progress = 0;
      this.feedBack = {};

      if (this.formRC.nueva) {
        // * Ser de mínimo 9 caracteres y máximo 15.
        if (this.formRC.nueva?.length >= this.minLength && this.formRC.nueva?.length <= this.maxLength) {
          this.progress += 1;
          this.feedBack.minMax = true;
        }
        // * Debe ser alfa numerico
        if (
          this.formRC.nueva?.match(/\d+/g)?.join("").length >= 1 &&
          this.formRC.nueva?.match(/[A-Za-z]+/g)?.join("").length >= 1
        ) {
          this.progress += 1;
          this.feedBack.alphanumeric = true;
        }
        // * Debe tener mínimo N letras en minuscula
        if (this.formRC.nueva?.match(/[a-z]+/g)?.join("").length >= this.minimoLetrasMinusculas) {
          this.progress += 1;
          this.feedBack.minMinusculas = true;
        }
        // * Debe tener minimo N letras en mayuscula
        if (this.formRC.nueva?.match(/[A-Z]+/g)?.join("").length >= this.minimoLetrasMayusculas) {
          this.progress += 1;
          this.feedBack.minMayusculas = true;
        }
        // * Debe tener mínimo N caracteres especiales
        if (this.formRC.nueva?.match(/\W+/g)?.join("").length >= this.specialCharacters) {
          this.progress += 1;
          this.feedBack.maxSpecialCharacters = true;
        }
        // * No puede ser igual a tu número de identificación.
        if (this.formRC.nueva !== this.$store.getters.identification) {
          this.progress += 1;
          this.feedBack.differentId = true;
        }
        // * No puede ser la misma contraseña
        const claveActual = this.recuperarClave ? this.$store.getters.clave : this.getHash(this.formRC.actual);
        if (claveActual !== this.getHash(this.formRC.nueva)) {
          this.progress += 1;
          this.feedBack.differentLast = true;
        }
        // * Deben coincidir los campos
        if (this.formRC.nueva === this.formRC.confirmada) {
          this.progress += 1;
          this.feedBack.passwordEqual = true;
        }
      }
      return this.progress;
    },
    cambiarClave() {
      this.$v.formRC.$touch();
      if (this.$v.formRC.$invalid) return;

      const body = {
        recuperarClave: this.recuperarClave,
        cedula: this.$store.getters.identificacion,
        claveNueva: this.getHash(this.formRC.nueva),
        valor: !this.recuperarClave ? this.getHash(this.formRC.actual) : null,
      };

      PublicService.cambiarClave(body).then((response) => {
        if (response.data.estado) {
          AlertsService.success("Cambio de clave", "Contraseña cambiada correctamente!");
          this.$store.commit("setUserClave", this.getHash(this.formRC.nueva));
          this.$emit("terminarProceso");
          this.cleanForm();
        } else {
          let msg = "Ups! Tu contraseña no cumple las condiciones de seguridad, por favor inténtalo de nuevo.";
          AlertsService.error("Cambio de clave", msg);
        }
      });
    },
    cleanForm() {
      Object.keys(this.formRC).forEach((key) => {
        this.formRC[key] = null;
      });
      setTimeout(() => this.$v.$reset(), 0);
    },
  },
};
</script>
